<template>
    <v-row>
        <v-col cols="12">
            <v-btn @click="salvar" fixed bottom right fab color="primary"><v-icon dark>mdi-content-save</v-icon></v-btn>
            <v-card>
                <v-toolbar color="primary" dark flat height="auto">
                    <v-toolbar-title class="py-2">Informações principais</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col cols="12" sm="6"><v-select hide-details label="* Idioma:" :items="selectIdioma" v-model="registro.id_disciplina" /></v-col>
                            <v-col cols="12" sm="6"><v-select hide-details label="* Level:" :items="selectLevel" v-model="registro.id_level" /></v-col>
                        </v-row>
                    </v-form>
                    <v-alert v-if="erro" type="error" class="mt-5"><div v-html="erro"></div></v-alert>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import axios from 'axios'
import {mask} from 'vue-the-mask'
import {mapState} from 'vuex'

export default {
    name: "UsuarioLevelForm",
    props : ['id_usuario'],
    directives : {mask},
    data() {
        return {
            registro : {},
            erro : null,

            selectIdioma : [],
            selectLevel : []
        }
    },
    computed : {
        ...mapState(['baseUrl', 'apiUrl'])
    },
    methods : {
        selectLevelListar() {
            return axios.post(`${this.apiUrl}aluno/level/listar`).then((res) => {
                this.selectLevel = res.data.lista.map((rt) => {
                    return {value : rt.id_level, text : rt.level}
                })
            })
        },
        selectIdiomaListar() {
            return axios.post(`${this.baseUrl}disciplina/list`).then((res) => {
                this.selectIdioma = res.data.lista.map((rt) => {
                    return {value : rt.id_disciplina, text : rt.disciplina}
                })
            })
        },
        salvar() {
            axios.post(`${this.apiUrl}usuario/level/rel/novo`, {id_disciplina: parseInt(this.registro.id_disciplina), id_level: parseInt(this.registro.id_level), id_usuario : parseInt(this.id_usuario)}).then( () => {
                this.erro = null
                this.$router.push({path : '/usuario/gerenciar/' + this.id_usuario})
            }).catch(e => {
                this.erro = this.erroFormatar(e)
            })
        },
        async init() {
            this.registro = {}
            await this.selectIdiomaListar()
            await this.selectLevelListar()
        }
    },
    activated : function() {
        this.init()
    }
}
</script>

<style scoped>

</style>
